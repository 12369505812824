<template>
  <div class="content-table-mobile" v-if="!bEmptyTable">
    <div v-if="bLoadingTable" class="content-progress-linear">
      <v-progress-linear
        indeterminate
        color="yellow darken-2"
      ></v-progress-linear>
    </div>
    <div v-else v-for="(itemTable, index) in aCard" :key="index">
      <v-container>
        <div class="content-all-first">
          <div class="content-left-first">
            {{ itemTable.sName }}
          </div>
          <!-- <div class="content-right-first">
     
          </div> -->
        </div>
        <div class="content-all">
          <div class="content-left">
            <p>#</p>
          </div>
          <div class="content-right">
            <p class="text-right">{{ itemTable.sNumber }}</p>
          </div>
        </div>
        <div class="content-all">
          <div class="content-left">
            <p>Institución</p>
          </div>
          <div class="content-right">
            <p class="text-right">{{ itemTable.sInstitution }}</p>
          </div>
        </div>
        <div class="content-all">
          <div class="content-left">
            <p>Tipo</p>
          </div>
          <div class="content-right">
            <p class="text-right">{{ itemTable.sType }}</p>
          </div>
        </div>
        <div class="content-all">
          <div class="content-left">
            <p>Variacíon</p>
          </div>
          <div class="content-right">
            <p class="text-right">
              {{ itemTable.dVariation }}
            </p>
          </div>
        </div>
        <div class="content-all">
          <div class="content-left">
            <p>Balance</p>
          </div>
          <div class="content-right">
            <p class="text-right">
              {{ itemTable.dBalance }}
            </p>
          </div>
        </div>
      </v-container>
      <v-divider class="divider-global mt-3 mb-3"></v-divider>
    </div>

    <footer-table-component-global
      :numPages="numPages"
      :totalItems="totalItems"
      @changeTable="getEmitFinancialAccounts"
    />

    <!-- <footer-table-component-global :numPages="numPages" :totalItems="totalItems" @changeTable="changeTable"
      :bChangeSelectedItem="bChangeSelectedItem" /> -->
  </div>
</template>

<script>
export default {
  props: {
    aCard: Array,
    aHeaders: Array,
    numPages: Number,
    totalItems: Number,
  },
  data() {
    return {
      bLoadingTable: true,
      bEmptyTable: false,
    };
  },
  beforeMount() {
    this.setLoadingTable();
  },
  methods: {
    setLoadingTable() {
      this.bLoadingTable = true;
      this.bEmptyTable = false;
      setTimeout(() => {
        this.bLoadingTable = false;
        this.bEmptyTable = this.aCard.length < 1;
      }, 1000);
    },
    openDetailItem(item) {
      this.$emit("openDetailItem", item);
    },
    openDeleteItem(item) {
      this.$emit("openDeleteItem", item);
    },
    getEmitFinancialAccounts(obj) {
      this.$emit("getEmitFinancialAccounts", obj);
    },
  },
  watch: {
    aCard() {
      this.setLoadingTable();
    },
  },
};
</script>

<style scoped>
.content-all-first {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
  margin-bottom: 10px;
}

.content-all {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
}

.content-left-first {
  width: 100% !important;
  display: flex;
  align-items: center;
  font-family: "pop-Bold";
  font-size: 18px;
}

.content-left {
  width: 50%;
  font-family: "pop-Semibold";
  font-size: 14px;
}

.text-right {
  /* white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden; */
}

.content-right-first {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
  position: relative !important;
}

.content-right {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
}

.content-right {
  overflow: auto;
}

.content-right::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.content-right::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb {
  background: var(--primary-color-scroll);

  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-scroll);
}

.content-table-mobile {
  background-color: var(--primary-color-background-table) !important;
  box-shadow: 0px 0px 10px 5px var(--primary-color-border-shadow) !important;
  border-radius: 10px;
}

.content-title {
  text-align: start;
}

.text-head {
  color: var(--primary-color-text) !important;
}

.content-text {
  /* text-align: end; */
}

.text-response {
  color: var(--primary-color-text) !important;
}

.text-menu-accion {
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-left: 5px;
}

.content-accion-menu {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.content-accion {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
</style>
